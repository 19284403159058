@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Splash {
	@include defaultPage();
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	min-height: 100vh;
	background-image: 
		url('../../assets/images/background-splash-top.svg'), 
		url('../../assets/images/background-splash-bottom.svg'),
		url('../../assets/images/background-splash-middle.svg');
	background-size: 100% auto;
	background-position: top center, bottom center, center center;
	background-repeat: no-repeat, no-repeat, repeat-y;
	transition: all 0.5s linear;
	&--active {
		display: block; 
	}
	&--slideOut {
		display: block;
		@include animateSlideOut();
	}
}

.Splash-play {
	position: absolute;
	left: calc(50% - 2.5em);
	top: 25em;
	width: 5em;
	height: 3.35em;
	background-image: url('../../assets/images/button-play.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		background-image: url('../../assets/images/button-play-hover.svg');
	}
	&.loading {
		cursor: not-allowed;
		&::after {
			content: "";
			position: absolute;
			left: calc(50% - 0.75em);
			top: 4em;
			width: 1.5em;
			height: 1.5em;
			@include origin(50%, 50%);
			-webkit-animation: loadingSpin 0.5s ease-in-out 0s infinite;
			animation: loadingSpin 0.5s ease-in-out 0s infinite;
			background-image: url('../../assets/images/icon-loading-key.svg');
			background-size: auto 1.5em;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
}
@keyframes loadingSpin {
	from {@include rotate(0deg);}
	to {@include rotate(360deg);}
}
@-webkit-keyframes loadingSpin {
	from {@include rotate(0deg);}
	to {@include rotate(360deg);}
}